export const isNullOrUndefined = (value) =>
  value === null || value === undefined;

export const isString = (value) =>
  typeof value === "string" || value instanceof String;

/*
 * Check if the value is a number.
 * A number can be a decimal, a number, or a bigint.
 * @param {any} value
 * @returns {boolean}
 */
export const isNumber = (value) =>
  value?.isDecimal?.() ||
  value?.toNumber?.() ||
  value?.isZero?.() ||
  typeof value === "number" ||
  typeof value === "bigint";

export const isBoolean = (value) => typeof value === "boolean";
